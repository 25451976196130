
hr.solid {  
    width: 100%;
    margin: .5em 0 .5em;
    border-top: 0.5px solid #c6c6c8;
}
.Carousel {
    position: relative;
    align-content: center;
    width: 100%;
    border-radius: 3vw 3vw 0 0;
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.1);
    /* bottom: 0; */
}

.Card-Item {
    pointer-events: auto;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 18.5vh;
    min-height: 144px;
    border-radius: 3vw 3vw 0 0;
    margin-left: 0;  
    /* padding-top: 0.5rem; */
    background-color: white;
    /* font-size: max(2.0vh,1rem); */
    font-size: 1rem;
}
.Card-Item#no_stop {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.1);
}
.Card-Item span {
    font-size: 1rem;
    /* font-size: max(14px, 2.0vh); */
    text-align: left;
    color: #848484;
    margin-bottom: 0.1vh;
}
.Info-Icon {
    margin-top: 0;
    margin-right: 0.5em;
    height: 16px;
    /* height: max(16px, 2.0vh); */
    color: #848484;
}
.Card-Item div {
    margin: 0 8% 0 8%;
}
.Card-Item .title {
    position: relative;
    display: flex;
    align-items: center;
    height: auto;
    margin-top: 1em;
    margin-bottom: 0.5vh;
    letter-spacing: -0.42px;
    color: #848484;
}
.Card-Item .table {
    display: table;
    width: 80%;
    height: 8vh;
    line-height: 1.57;
    letter-spacing: -0.42px;
    text-align: left;
}
.Card-Item .table-row {
    height: 2em;
    display: table-row;
}
.Card-Item .table-cell {
    display: table-cell;  
    vertical-align: middle;
}
.Card-Item .table-pivot {
    width: 40%;
    color: #5a5a5a;
}
.Card-Item .table-content {
    font-weight: 500;
    color: #2f2f2f;
}
.Card-Item .stop-number {
    position: relative;
    width: fit-content;
    /* height: 22px; */
    /* left: 10%; */
    top: 20px;
    margin: 0 0 0.5em 0;
    padding: 1px 7px 2px;
    border-radius: 5px;
    border: solid 1px #36f;
    font-size: 0.8em;
    font-weight: 500;
    letter-spacing: -0.13px;
    text-align: center;
    color: #36f;
}
.Card-Item div.stop-img-wrapper{
    display: flex;
    position: absolute;
    right: 8vw;
    height: 100%;
    margin: 0;
    width: auto;
    align-items: center;
    justify-content: center;
}
.Card-Item .stop-img {
    position: relative;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
    height: 38%;
    width: auto;
    cursor: pointer;
}
.Card-Item div.stop-img-wrapper .roadview-icon{
    position: absolute;
    font-size: 20px;
    height: 14%;
    color: white;
    pointer-events: none;
}
.Card-Item .stop-name {
    position: relative;
    width: 70%;
    height: auto;
    margin: 1.3em 0 0 0;
    font-size: 1.1em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.45px;
    text-align: left;
    color: #171717;
}
.Card-Item .ETA-box {
    display: flex;
    width: 65%;
    height: auto;
    margin: 0.6em 0 0 0;
    font-size: 0.85em;
    letter-spacing: -0.36px;
    text-align: left;
    align-items: center;
}
.Card-Item .ETA {
    width: fit-content;
    min-width: 50px;
    margin: 0 0.8vw 0 0;
    color: #848484;
}
.Card-Item .ETA-info {
    /* display: inline-block; */
    width: fit-content;
    flex: 1;
    max-width: 70%;
    word-break: keep-all;
    margin: 0;
    font-weight: 500;
    color: #d1443c;
}
.carousel-control-prev, .carousel-control-next {
    width: 10%;
    pointer-events: painted;
}

div.roadview-top{
    width: 100%;
    height: auto;
    padding-top: 13px;
    padding-bottom: 13px;
    z-index: 10;
    background-color: #f1f1f5;
    position: fixed;
    display: none;
}
div.roadview-top.on{
    display: inline-flex;
    align-items: center;
}

div.roadview-top div.roadview-back-btn{
    float: left;
    width: 10%;
    height: 18px;
    text-align: center;
}
div.roadview-top div.roadview-back-btn svg{
    vertical-align: baseline;
    height: 100%;
    margin-left: 0%;
    cursor: pointer;
    pointer-events: visible;
    color: #848484;
}
div.roadview-top div.roadview-description {
    text-align: center;
    display: inline-block;
    vertical-align: bottom;
    width: 80%;
    height: auto;  
    font-weight: 500;
    font-size: 14px;
}
div.roadview-pin-btn {
    position: absolute;
    bottom: 120px;
    right: 10px;
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) !important;
    background-color: #fff ;
    color: black ;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: visible;
    z-index: 4;
}
div.roadview-pin-btn svg{
    font-size: 20px;
}
div.roadview-pin-btn:active svg{
    color: blue;
}