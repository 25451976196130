div, span {
    font-family: Noto Sans KR;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.39px;
}

div .agree-info-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

div div.agree-info-content div {
    font-size: 12.5px;
    line-height: 1.7;
    color: #5a5a5a;
    margin-bottom: 3px;
}
div ul.agree-info-content li {
    font-size: 12.5px;
    line-height: 1.7;
    color: #5a5a5a;
    margin-bottom: 3px;
    margin-left: 20px;
}
div div.agree-info-content b,strong {
    font-weight: 500;
    color: #171717
}
div ul.agree-info-content b,strong {
    font-weight: 800;
    color: #5a5a5a
}
div.line-divider {
    width: 100%;
    height: 12px;
    margin: 15px 0 20px;
    background-color: #f1f1f5;
}

.Rideflux-Inc-bottom {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.1rem;
    bottom: 0;
    font-size: 10px;
    letter-spacing: -0.2px;
    background-color: #f1f1f5;
    color: #5a5a5a;
}
img.RF-Logo {
    height: 5vh;
    min-height: 37px;
    margin-right: max(10px,1.5vw);
}

div.modal-agreementText{

}
div.modal-agreementText p{
    margin-block-start: 1em;
    margin-block-end: 1em;
}
div.modal-agreementText div.sub1{
    padding-top: 1em;
}
div.modal-agreementText div.sub2{
    text-indent: -.7em;
    padding-left: 1.5em;
}
div.modal-agreementText div.sub3{
    text-indent: -.7em;
    padding-left: 2.5em;
}

/* [type="radio"] */
.custom-radio-shape  {
    position: relative;
}
.custom-radio-shape input {
    display: none;
}
.custom-radio-shape input+label {
    position: relative;
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: 0;
    gap: 0.5rem;

    cursor: pointer;
    color: #707991;
}
.custom-radio-shape input:checked+label {
    color: #3569FE;
}
.custom-radio-shape input+label::before {
    content: '';
    display: block;
    height: calc(1.5em + 2px);
    width: calc(1.5em + 2px);
    
    color: currentColor;

    border-width: 1.5px;
    border-style: solid;
    border-color: currentColor;
    border-radius: 50%;
    background: transparent;
}
.custom-radio-shape input:checked+label::before {
    color: currentColor;
}
.custom-radio-shape input+label::after {
    display: none;
}
.custom-radio-shape input:checked+label::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: calc((1.5em + max(0.1em, 2px)) / 2);
    width: 1em;
    height: 1em;

    transform: translate(-50%, -50%);
    border: none;
    border-radius: 50%;
    background: currentColor;
}
/* .custom-radio-shape input+label:hover::before {
    outline: 1;
} */
.custom-checkbox-shape {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    font-weight: 500;
    font-size: 14px;

    width: 100%;
}
.custom-checkbox-shape button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    margin: 0;
    gap: 44px;
    width: 100%;

    text-align: left;
    
    color: #464F69;
    background: #F7F8FA;

    border: 1px solid #CBD2DD;
    border-radius: 8px;
}
.custom-checkbox-shape button .custom-radio-shape input+label {
    color: #98A0B3 !important;
}
.custom-checkbox-shape button .custom-radio-shape input:checked+label {
    color: #22CEAF !important;
}
.custom-checkbox-shape button:hover {
    color: #464F69;
    background: #F7F8FA;

    border: 1px solid #CBD2DD;
}
.custom-checkbox-shape button:focus {
    color: #464F69 !important;
    background: #F7F8FA;
    border: 1px solid #CBD2DD !important;
    box-shadow: 0 0 0 0.2rem rgb(203 210 221 / 25%);
}
.custom-checkbox-shape button:active {
    color: #0E7468 !important;
    background: #DBFEF3 !important;
    border: 1px solid #22CEAF !important;
    opacity: 0.5;
}
.custom-checkbox-shape button:active:focus {
    box-shadow: 0 0 0 0.2rem rgb(34 206 105 / 25%) !important;
}
.custom-checkbox-shape button.active {
    color: #0E7468 !important;
    background: #DBFEF3 !important;
    border: 1px solid #22CEAF !important;
}
.custom-checkbox-shape button.active:focus {
    color: #0E7468 !important;
    background: none;
    border: 1px solid #22CEAF !important;
    box-shadow: 0 0 0 0.2rem rgb(34 206 105 / 25%) !important;
}
.custom-checkbox-shape button.active:active {
    color: none !important;
    background: #F7F8FA !important;
}

.custom-text-shape {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    margin: 0;
}
.custom-text-shape * {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}
.custom-text-shape *:not(.invalid-feedback) {
    color: #323B54;
}
.custom-text-shape *.text-muted {
    color: #707991 !important;
}
.custom-text-shape label {
    font-weight: 500;
    letter-spacing: -0.02em;
    margin: inherit;
}
.custom-text-shape input { 
    font-weight: 500;
    padding: 14px 35px 14px 16px;
    font-size: 14px;
    height: auto;
    border: 1px solid #B2BBCA;
    border-radius: 8px;
}
.custom-text-shape input:focus {
    border: 1px solid #3569FE;
    box-shadow: 0px 4px 4px rgba(143, 162, 213, 0.15);
}
.custom-text-shape input.is-valid { 
    background: #F7F8FA;
    border: 1px solid #C9CAD3;
}
.custom-text-shape input.is-valid:focus {
    background: #FFFFFF; 
    border-color: #3569FE;
    box-shadow: 0 0 0 0.2rem rgb(143 162 213 / 0.15);
}
.custom-text-shape small.form-text {
    font-weight: 500;
    margin: 0;
    font-size: 12px;
}
.custom-text-shape input.is-invalid {
    background-image: none;
}

body.dark-mode {
    background-color: #171717 !important;
}
body.dark-mode :has(div,span,p,b,pre,table,tr,td,th,thead,tbody) {
    color: rgba(255, 255, 255, 0.85);
}

div.admin-top-bar {
    margin: auto;
    width: 90%;
    padding: 10px 0;
    color: black;
}
div.admin-top-bar::after {
    display: block;
    content: '';
    clear: both;
}

.text-blue {
    color: blue !important;
}
.text-red {
    color: red !important;
}
body.dark-mode .text-blue {
    color: rgb(239, 102, 239) !important;
}