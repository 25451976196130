.Seogwipo-logo-box.passenger {
    margin-bottom: 2rem
}

.Seogwipo-Main-round-box.passenger{
    /* top: ; */
    height: auto;
    /* overflow-y: hidden; */
    padding-bottom: 0;
    font-size: 1rem;
}

p.Seogwipo {  
    width: 90%;
    height: auto;
    margin-left: 5%;
    margin-bottom: 0;
    font-family: Noto Sans KR;
    font-size: 1em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.4;
    letter-spacing: -0.45px;
    text-align: left;
    color: #171717;
}
.form {
    position: relative;
    left: 5%;
    width: 90%;
}
.form .MuiFormControl-root{
    margin-bottom: min(3%,20px);
}
div.form-wrapper {
    display: flex;
    flex-direction: column;
}
span.send-sms-info {  
    font-size: 12.5px;  
    line-height: 3;
    letter-spacing: -0.36px;
    position: relative;
    margin-left: 5%;
}

.consent-form {
    width: 100%;
    height: auto;
    margin-bottom: min(5%,20px);
}
.consent-form.jumbotron {
    width: 90%;
    margin: min(5%,30px) 5% 0 5%;
    padding: min(max(1.5%, 10px),16px) min(5%, 30px)
}
.btn-consent-form {
    display: block;
    margin:0;
    margin-top: min(3%, 10px);
    padding: 0;
    border: none !important;
    color: #2163b1 !important;
    background-color: transparent !important;
    font-size: min(12px,3vh);
    letter-spacing: -.39px;
}
div.agree-box {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: min(7.5%, calc(5% + 20px));
}
.checkbox{
    margin: 0 .5vh .5vh 0;
}
.checkbox.fill {
    color: #36f;
}
#agree-btn {  
    text-align: left;
    color: #5a5a5a;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    line-height: 2.4;
    background-color: transparent;
    font-size: 14px;
}
#agree-btn span{
    font-weight: 500;
}
#agree-btn span.on{
    color: #36f;
}
.press-btn.Seogwipo{  
    position: relative;
    width: 90%;
    height: 3rem;
    margin: 2.5vh 5% 2.0em 5%;
    font-size: 1rem;
    letter-spacing: -0.16px;
    color: #f8f8f8;
}