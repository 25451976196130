
div.control-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
div.control-panel button {
    margin: auto;
}
button.sangam-btn {
    max-width: 300px;
    width: 100%;
    font-size: 1.5rem
}

div.destination-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
}
div.destination-btns > div.current-destionation {
    font-size: 1.8rem;
    word-break: keep-all;
}
button.destination-btn {
    padding-block: 2rem; 
    margin-block: 1rem;
}