.Seogwipo-bg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    background-image: linear-gradient(to right, #36f, #5c41f5);
    z-index: 0;
}
.Seogwipo-bg.alt {
    background-image: linear-gradient(to bottom, #36f, #5c41f5);
}
@media only screen and (min-width: 700px) {
    br.show-mobile {
        display: none;
    }
}

.main-name {
    width: 60%;
    height: 64px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.45;
    letter-spacing: -0.44px;
    color: #171717;
}

.service-info {
    position: absolute;
    width: 90%;
    height: auto;
    top: 20%;
    margin: 0% 5% 0 5%;
    line-height: 1.67;
    letter-spacing: -0.48px;
    color: #171717;
}

.service-info div.service-info-text {
    width: 100%;
    height: auto;
    margin: 0 5% 1.7rem 5%;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: -0.48px;
    color: #171717;
    word-break: keep-all;
}

.service-info div.service-info-round-box {
    width: 100%;
    height: auto;
    font-size: 14px;
    border-radius: 16px;
    padding: 11px 0% 0.5rem 0%;
    margin-bottom: 1rem;
    line-height: 2.57;
    letter-spacing: -0.28px;
    background-color: rgba(255, 255, 255, 0.7);
}
.service-info div.service-info-round-box span {
    margin: 0 8% 0 8%;
    letter-spacing: -0.28px;
    color: #484848;
}
.service-info div.service-info-round-box .MuiDivider-root {
    height: 0.5px;
    background-color: #c6c6c8;
    margin: 0.5rem 0 0.5rem 0;
    width: 100%;
}
.service-info div.service-info-round-box div.table {
    display: table;
    width: 84%;
    line-height: 2.67;
    margin: 0 8% 0 8%;
    letter-spacing: -0.45px;
    text-align: left;
}
.service-info div.service-info-round-box div.table-row {
    width: fit-content;
    display: table-row;
}
.service-info div.service-info-round-box div.table-row div {
    color: #5a5a5a;
    display: table-cell;
}
.service-info div.service-info-round-box div.table-row div.table-pivot {
    width: 15%;
}
.service-info
    div.service-info-round-box
    div.table-row
    div.table-pivot#bold_pivot {
    font-weight: 500;
    color: #36f;
}
.service-info
    div.service-info-round-box
    div.table-row
    div.table-cell#bold_cell {
    font-weight: 500;
    color: #2f2f2f;
}
div.table-cell div.small {
    font-size: 0.8em;
}
div.table-cell div.indent {
    padding-left: 0.5em;
}

.service-info div.sub-text {
    width: 92.5%;
    margin: 0 5% 0 5%;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: -0.48px;
    text-align: left;
    color: #484848;
}
.on-service-wrapper {
    position: relative;
    top: 0;
    margin: 0;
    width: 100%;
    height: fit-content;
    padding: 4.5em 0 2.2em 0;
}
.on-service-wrapper .bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    background-image: linear-gradient(to bottom, #3366ff, #5c41f5);
}
.service-info.on-service {
    position: relative;
    top: 0%;
}
.service-info.on-service div.service-info-text {
    margin-bottom: max(20px, 0.8em);
}
.service-info.on-service div.service-info-eta {
    width: 100%;
    height: auto;
    font-size: 1em;
    font-weight: 500;
    margin: 0 5% 0 5%;
    letter-spacing: -0.48px;
    color: #171717;
}
.service-info.on-service div.service-info-eta b {
    color: #3366ff;
}

.service-completed-wrapper {
    top: 0;
    height: auto;
    min-height: 100%;
    padding-top: 15vh;
    padding-bottom: 2em;
}

.service-completed-wrapper div.image-box {
    display: flex;
    width: 40vw;
    height: 40vw;
    margin: 0 25vw;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    margin-bottom: 1.5rem;
    overflow: hidden;
}

@keyframes carmove {
    0% {
        margin-left: 150%;
    }
    100% {
        margin-left: 5%;
    }
}
.service-completed-wrapper img {
    margin-top: 5%;
    margin-left: 5%;
    width: 75%;
    height: auto;
    animation: carmove 0.5s;
}
.service-completed-wrapper div.service-completed-text {
    margin-bottom: max(20px, 0.8em);
    width: auto;
}
.service-completed-wrapper div.service-completed-text.center {
    text-align: center;
}
.service-completed-wrapper div.customer-service-info {
    margin: 0 0 50px 5%;
    width: inherit;
    font-size: 1em;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #5a5a5a;
}
.service-completed-wrapper div.customer-service-info.center {
    text-align: center;
}

div.map-container {
    position: relative;
    width: 100%;
    flex: 1;
    height: auto;
}

.press-btn {
    position: absolute;
    width: 80%;
    height: 3rem;
    bottom: 0;
    margin: 0 10% 1em 10%;
    border-radius: 10px;
    border: none;
    background-image: linear-gradient(to right, #3366ff, #5845f6 48%, #5c41f5);
    font-family: Noto Sans KR;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: center;
    color: #f8f8f8;
}
.press-btn:focus {
    box-shadow: none !important;
}
.press-btn#able {
}
.press-btn#disable {
    background-image: none;
}

.press-btn.service-completed {
    margin: 0 5% 1em 5%;
    width: 90%;
}
.backward-btn-box {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 2rem;
    height: 2rem;
    top: 20px;
    left: 5%;
    border-radius: 50%;
    border: none;
    color: black !important;
    box-shadow: 0 0 5px 0 rgba(10, 1, 1, 0.2) !important;
    background-color: rgba(255, 255, 255, 1) !important;
    font-size: 1.5rem;
}
.backward-btn-box.right {
    left: auto;
    right: 5%;
}
.gps-box {
    display: flex;
    margin-left: calc(95% - 40px);
    margin-bottom: 3%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) !important;
    background-color: #fff;
    color: black;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: auto;
}
.gps-box.bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
    margin: 0;
}
.gps-box:active {
    background-color: white !important ;
    color: #36f !important;
}
.gps-box:hover {
    background-color: white;
    color: black;
}
.gps-box:focus {
    background-color: white;
    color: black;
}

div.initial-info-box {
    width: 90%;
    margin: 5%;
    padding: 1em 0 1.1em 0;
    border-radius: 3vw;
    border: solid 1px #c63931;
}
div.initial-info-box div.title {
    margin-bottom: 0.8em;
    text-align: center;
    font-weight: 700;
    font-size: 1em;
    text-align: center;
    color: #171717;
}
div.initial-info-box div.title span {
    font-weight: 700;
}
div.initial-info-box .alert-mark {
    font-size: 1.2em;
    color: #c63931;
    margin-bottom: 0.2em;
    margin-right: 0.5em;
    vertical-align: middle;
}
div.initial-info-box div.contents div {
    margin-bottom: 0.5em;
    margin-left: calc(10% + 1em);
    margin-right: 10%;
    text-align: left;
    font-weight: 500;
    font-size: 3vw;
    letter-spacing: -0.26px;
    word-break: keep-all;
    color: #c63931;
    text-indent: -1em;
}
.Card-Item-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-top: 10px;
    height: auto;
    overflow: hidden;
    pointer-events: none;
}
