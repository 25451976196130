.Seogwipo-MapContent.map_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}
.Seogwipo-MapContent.mini {
    position: relative;
    width: 100%;
    height: 40%;
    min-height: 350px;
    z-index: 0;
}
.Seogwipo-MapContent.mini.admin {
    height: 40vh;
    margin-bottom: 2vh;
}
.Seogwipo-MapContent div.custom-overlay-box {
    display: flex;
    justify-content: center;
    pointer-events: none;
    
}
.Seogwipo-MapContent img.custom-overlay-img {

}
#base_map {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
}
#base_roadview {
    width: 100%;
    height: 100%;
    z-index: 4;
    position: relative;
}
div.custom-overlay-box div.custom-overlay-text-box {
    display: flex;
    position: absolute;
    height: auto;
    width: auto;
    top: 10%;
    left: 45%;
    font-weight: 500;
    text-align: center;
    align-items: center;
    justify-content: center;
}   
div.custom-overlay-box div.custom-overlay-text-box.moving {
    width: 137%;
    left: 38%;
}   
div.custom-overlay-box div.custom-overlay-text-box.arrival {
    width: 145%;
    left: 27%;
    top: 11%;
}   
div.custom-overlay-box div.custom-overlay-text-box.leaving {
    width: 147%;
    left: 27%;
    top: 12%;
}   

div.roadview-custom-overlay {
    pointer-events: none;
    text-align: center;
    font-weight: bold;
    font-size:10pt;
    width:148px;
}

img.car-marker {
    width: 50px;
    height: 50px;
    pointer-events: none;
    user-select: none;
}
img.car-marker.ioniq {
}
img.car-marker.pacifica {
    width: 55px;
    height: 55px;
}