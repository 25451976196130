.test-admin.title {
    height: 4rem;
}
button.push-button {
    margin-block: 1rem;
    width: 300px;
}

div.test-admin.map-containter {
    width: 100%;
    height: 45vh;
    z-index: 0;
}

@media (max-width: 700px) {
    button.push-button {
        height: 20vh;
        max-height: 200px;
        margin-block: 0.5rem;
    }
    button.test-admin-invisible {
        display: none;
    }
    div.test-admin.map-containter {
        /* flex-basis: 200px; */
        
        /* height: 100px; */
        height: 55vh;
        max-height: 350px;
    }
    .test-admin.title {
        display: none;
    }
    .reactive-font {
        font-size: 0.7rem;
    }
}