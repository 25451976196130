/* noto-sans-kr-300 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('styles/fonts/noto-sans-kr-v21-latin_korean-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('styles/fonts/noto-sans-kr-v21-latin_korean-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-300.woff') format('woff'), /* Modern Browsers */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-regular - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('styles/fonts/noto-sans-kr-v21-latin_korean-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('styles/fonts/noto-sans-kr-v21-latin_korean-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-regular.woff') format('woff'), /* Modern Browsers */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-500 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('styles/fonts/noto-sans-kr-v21-latin_korean-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('styles/fonts/noto-sans-kr-v21-latin_korean-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-500.woff') format('woff'), /* Modern Browsers */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('styles/fonts/noto-sans-kr-v21-latin_korean-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('styles/fonts/noto-sans-kr-v21-latin_korean-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-700.woff') format('woff'), /* Modern Browsers */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-900 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('styles/fonts/noto-sans-kr-v21-latin_korean-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('styles/fonts/noto-sans-kr-v21-latin_korean-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-900.woff') format('woff'), /* Modern Browsers */
       url('styles/fonts/noto-sans-kr-v21-latin_korean-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
@font-face {
	font-family: 'SUIT Variable';
	font-weight: 100 900;
	src: url('styles/fonts/SUIT-Variable.woff2') format('woff2-variations');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

