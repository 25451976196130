.Seogwipo-Floating-toast-box {
    pointer-events: none;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 58%;  
    max-width: none;
    width: 90%;
    margin: 0 5% 0 5% !important;
    height: auto;
    padding: 0.66em 0 0.8em 0;
    border: none;
    border-radius: 10px;
    box-shadow: none;
    align-items: center;
    text-align: center;
    font-size: 13px;
    color: #f8f8f8;
    z-index: 5;
}
.Seogwipo-Floating-toast-box.recommend {
    background-color: rgba(132, 132, 132, 0.9);
}
.Seogwipo-Floating-toast-box.toast.show {
    opacity: 0.85;
    background-color: #5a5a5a;
}
.Seogwipo-Floating-toast-box div.text{
    position: relative;
    font-weight: 500;
}
.Seogwipo-Floating-toast-box div.text.small{
    font-size: 12px;
}
.Seogwipo-Floating-toast-box div.button{
    position: relative;
    width: auto;
    margin: .5em 0 .0em 0;
    border: 2px solid white;
    padding: 5px 20px 5px 25px;  
    border-radius: 15px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #fff;
    background-color: rgba(114,114,114,0.91);
    font-size: 13px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    pointer-events: visible;
    font-weight: bold;
    user-select: none;
}
.Seogwipo-Floating-toast-box div.button .arrow-forward{
    margin-bottom: 0.1em;
}
div.valid-area-info-box {
    position: absolute;
    top: min(2%,20px);
    left: min(5%,15px);
    width: auto;
    height: auto;
    margin: 0;
    padding: 6px 10px 7px;
    opacity: 0.95;
    border-radius: 10px;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;  
    font-size: 12px;
    pointer-events: none;
}
div.valid-area-info-box div.box{
    display: inline-block;
    vertical-align: bottom;
    width: 22px;
    height: 1em;
    margin-bottom: .2em;
    margin-right: 5px;
}
div.valid-area-info-box div.box#able{
    background-color: rgba(51, 102, 255, 0.4)
}
div.valid-area-info-box div.box#disable{
    background-color: rgba(132, 132, 132, 0.5);
}

div.invalid-warning-bg {
    position: absolute;
    height: 100%;
    width: 100%;  
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
    z-index: 2;
}
div.invalid-warning-box {
    position: absolute;
    width: 100%;
    top: 38%;
    user-select: none;
    -webkit-user-drag: none;
}
div.invalid-warning-box div{
    color: white;  
    font-weight: bold;
    margin: 0 auto;
    width: max-content;
}
div.invalid-warning-box div.title{
    top:37.5%;
    letter-spacing: -0.48px;
}
div.invalid-warning-box div.button{
    top:40%;
    height: 2.3em;
    margin: 1rem auto;
    padding: 0.3em 1.3em 0.4em 1.5em;
    border-radius: 18px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);  
    letter-spacing: normal;
    border: solid 1px #fff;
    background-color: #727272;
    pointer-events: visible;
    cursor: pointer;
}

.Card-Item.floating {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    /* min-height: 144px; */
    padding-top: 0.8rem;
    margin-left: 0;
}
.Card-Item.floating div.address{
    position: relative;  
    height: 1.4rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    font-size: 16.3px;
    font-weight: 500;
    color: #2f2f2f;
}
.Card-Item.floating div.detail-address{
    position: relative; 
    height: 1rem; 
    top: 0.2rem;
    font-size: 13px;
    color: #5a5a5a;
}
.Card-Item.floating.fit{
    height: auto;
}
.Card-Item.floating div.address-box{
    /* position: absolute; */
    display: flex;
    width: 90%;
    height: auto;
    margin: 2% 5% 0 5%;
    padding: 0.5em 1em 0.5em 1em;
    border-radius: 1em;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    align-items: center;
    justify-content: space-around;
}
.Card-Item.floating div.address-box span{
    width: 40%;
    font-size: 0.9em;
    font-weight: 500;  
    letter-spacing: -0.45px;
    text-align: center;
    color: #171717;
    overflow:hidden;
    white-space: nowrap;
    mask-image: linear-gradient(to right, rgba(0,0,0,1) 90%, transparent 100%);
}
.Card-Item.floating div.address-box span.left{
    left: 9%;
}
.Card-Item.floating div.address-box span.right{
    left: 54.5%;
}
.Card-Item.floating div.address-box .arrow-forward{
    height: 1.2em;
    left: 44%;
    font-size: 1em;
    margin: 0.2em 0.5em 0.05em 0.5em;
    color: #3366ff;
}

.press-btn.floating-main {
    position: absolute;
    margin-bottom: 30px;
}

.press-btn.floating-select {
    position: relative;
    width: 90%;
    height: 3rem;
    margin-top: min(5%,2em);
    margin-left: 5%;
    margin-right: 5%;
}

div.customer-info-btn-wrapper {
    width: 90%;
    margin: 1.2em 5% 0% 5%;
    height: auto;
    font-size: 0.9em;
}
div.customer-info-btn-box {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 2.5% 0 2.5% 0;
    height: 3em;
} 
div.customer-info-btn-box .column {
    padding: 0;
}
div.customer-info-btn-box .column.active {
    z-index: 10;
}
div.customer-info-btn-box .column .select-btn {
    display: inline;
    text-align: center;
    width: calc(100% + 1.5px);
    height: 100%; 
    font-size: 1em;
    color: #848484;
    border: solid 1.5px #d1d1d1;
    border-radius: 0;
    background-color: transparent;
}
div.customer-info-btn-box .column:first-child .select-btn {
    border-radius: 1.5em 0 0 1.5em / 50%;
    border-right: 0;
}
div.customer-info-btn-box .column:last-child .select-btn {
    border-radius: 0 1.5em 1.5em 0 / 50%;
    border-left: 0;
}
div.customer-info-btn-box .column .select-btn.active {
    font-weight: 500;
    border: 2px solid;
    background-color: transparent;
    border-color: #3366ff;
    color: #3366ff;
}

img.passenger-icon {
    height: 0.9em;
    margin-right: 0.5em;
    margin-bottom: 0.2em;
}

/************************** confirm page ****************************/
.gps-box.confirm {
    bottom: max(30%, 240px);
}
hr.confirm {
    margin: .6em 0 .8em 0;
}
.Card-Item.floating.confirm {
    display: flex;
    flex-direction: column;
    height: 25%;
    min-height: 205px;
    margin-left: 0;  
    padding-top: max(1.2em, 20px);
    text-align: left;
}
.Card-Item.floating.confirm div{
    width: 90%;
    max-width: calc(100vw - 45px);
    margin-left: max(5%,30px);
    margin-right: max(2.5%,15px);
}
.Card-Item.floating.confirm div.title{
    font-size: 1.13em;
    /* font-size: 18px; */
    font-weight: bold;
    letter-spacing: -0.54px;
    color: #2f2f2f;
    margin-top: 0;
}
.Card-Item.floating.confirm div.title.blue{
    color: #3366ff;
}
.Card-Item.floating.confirm div.content{
    display: flex;
    margin-bottom: 0.4em;
    align-items: center;
}
.Card-Item.floating.confirm div.content span.pivot{
    display: inline-block;
    margin-right: 1em;
    font-size: 0.8em;
    /* color: #848484; */
}
.Card-Item.floating.confirm div.content span.cell{
    display: inline-block;
    font-size: 0.9em;
    font-weight: 500;
    max-width: 40%;
    color: #171717;
    word-break: keep-all;
    text-align: center;
}
.Card-Item.floating.confirm .cancel-btn{
    position: absolute;
    display: flex;
    width: auto;
    height: 2.2em;
    border: none;
    margin-right: max(15px, 5%);  
    padding: 4px 7px 4px 8px;
    top: max(1.2em, 20px);
    right: 0;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;  
    font-size: 0.66em;
    /* font-size: 12px; */
    letter-spacing: -0.36px;
    text-align: center;
    align-items: center;
    color: #848484;
}

.Card-Item.floating.confirm .arrow-forward{
    color: #36f;
    margin: 0 0 0 0.25em;
}