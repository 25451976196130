img.tamra-Logo {
    width: 10%;
    margin-top: 20%;
}
.tamra-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 3rem 1rem calc(80px + 2em + 3rem);;

    min-height: 100vh;
    min-width: fit-content;
    overflow-x: hidden;
}
.tamra-main * {
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
}
.tamra-main > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    flex-grow: 1;
}
.tamra-main > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    flex-grow: 1;
}
.tamra-main > div > div.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 100%;
}
.tamra-main .tamra-main-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-block: auto;
    margin-bottom: 50px;

    width: 300px;
    overflow-x: visible;
}
.tamra-main .tamra-main-title > span {
    font-weight: 300;
    font-size: 14px;
    color: #373737;
    padding-left: 3px;
}
.tamra-main .tamra-main-title > div.service-info-link {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding-top: 4px;
    color: rgb(123, 122, 122);
}
.tamra-main .tamra-main-title > div.service-info-link > span {
    cursor: pointer;
}
.tamra-main .tamra-main-title > div.service-info-link > svg {
    margin-bottom: 0.1em;
}

.tamra-main-select-wrapper {
    display: flex;
    flex-direction: column;
    margin-block: auto;
    gap: 1rem;

    overflow: auto;
    min-width: 300px;
}
.tamra-main-select {
    position: relative;
    height: fit-content;
    border-radius: 16px;

    overflow: hidden;
    cursor: pointer;
}
.tamra-main-select > img {
    min-width: 100%;
    height: 128px;
}
.tamra-main-select > div.car-img {
    position: absolute;
    top: 5px;
    left: 39%;
    box-shadow: 0px 6.28372px 11.6698px -38.6px rgba(0, 0, 0, 0.25);
    pointer-events: none;
}
.tamra-main-select > div.car-img > img {
    height: 150px;
}
.tamra-main-select > div.box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    gap: 1.8px;
    
    background: #F8F8FC;
    
}
.tamra-main-select > div.box > .arrow-icon {
    font-size: 18px;
    color: #5C5C5C;
    cursor: pointer;
}
.tamra-main-select > div.box > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
}
.tamra-main-select > div.box > div > span:first-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;

    color: #0FA19E;
}
.tamra-main-select > div.box.blue > div > span:first-child {
    color: #2552C3;
}
.tamra-main-select > div.box.pink > div > span:first-child {
    color: #ff593a;
}
.tamra-main-select > div.box > div > span:last-child {
    font-weight: 500;
    font-size: 14px;
    color: #323B54;
    line-height: 20px;
}
.tamra-main-select > div.box > div > span:last-child > span {
    font-size: 12px;
    color: #98A0B3;
    line-height: 140%;
}

.tamra-main-footer {
    display: flex;
    flex-direction: column;
    gap: 0px;

    position: fixed;
    width: 100%;
    bottom: 0;
}
.tamra-main-footer * {
    flex: none;
    flex-grow: 0;
}
.tamra-main-footer > .service-time-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    background: #EFF0F5;
    color: #707991;
    width: calc(100% + 2rem);
    flex-grow: 0;
}
.tamra-main-footer > .service-time-info > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px;
    gap: 10px;
    

    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.02em;

}
.tamra-main-footer > .service-time-info b {
    font-weight: 700;
}
.tamra-main-footer > div.register-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1px;

    height: 80px;
    
    padding: 1.5rem 2rem;
    background-color: #000;
    color: #FFF;
    cursor: pointer;
}
.tamra-main-footer div.register-box > svg {
    cursor: pointer;
}
.tamra-main-footer div.register-box > div > div {
    font-weight: 700;
    line-height: 140%;
}
.tamra-main-footer div.register-box > div > div:nth-child(1) {
    font-size: 12px;
    color: #F7F8FA;
    
    opacity: 0.6;
}
.tamra-main-footer div.register-box > div > div:nth-child(2) {
    font-size: 14px;
}
.tamra-main-footer svg {
    width: 2rem;
    height: 2rem;
}

.tamra-footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0;
    z-index: 10;

    position: absolute;
    width: 100%;
}
.tamra-footer-wrapper.bottom {
    bottom: 0;
}
.tamra-footer-wrapper.top {
    top: 0;
}
.tamra-footer-wrapper.relative {
    position: relative;
}
.tamra-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 1.5rem 1.75rem 3rem;
    width: 100%;
    gap: 16px;
    z-index: 10;

    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}
.location-button-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(0, calc(-100% - 20px));
}
.tamra-location-button {
    width: fit-content;
    height: fit-content;
    margin: 20px;

    position: relative;
    /* right: 0; */
    /* top: -20px; */
    /* transform: translate(0, calc(-100% - 20px)); */
    padding: 9px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(133, 135, 138, 0.25);
    border-radius: 50%;
    cursor: pointer;
}
.tamra-location-button > img {
    width: 100%;
    height: 100%;
}
.tamra-footer .line-divider {
    margin: 0 -1.75em;
    width: 100vw;
}
.tamra-footer.bottom {
    bottom: 0;
}
.tamra-footer.relative {
    position: relative;
}
.tamra-footer.additional-padding {
    padding-bottom: calc(80px + 3rem);
}
.tamra-footer.top {
    position: absolute;
    top: 0;
}
.tamra-footer .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width:100%;
}
.tamra-footer .title > div {
    font-weight: 600;
}
.tamra-footer .title > div b {
    color: #3569FE;
    font-weight: inherit;
}
.tamra-footer.jungmun .title > div b.green {
    color: #04A7A7;
}
.tamra-footer .direction.wrapper {
    padding: 0px;
}
.tamra-footer .direction {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    
    border-radius: 8px;
    width: 100%;

    font-style: normal;
    font-weight: 600;
}
.tamra-footer .direction span {
    font-weight: inherit;
}
.tamra-footer svg.arrow-icon {
    background: #E8E8EF;
    border-radius: 50%;
    height: 100%;
    color: #707991;
    min-width: 2rem;
    height: 2rem;
    padding: 4px;
}
.tamra-footer img {
    height: 1em;
}
.tamra-footer .direction.off {
    background: #EFF0F5;
    color: #9697A3;
}
.tamra-footer .direction.on {
    background: #EDF2FF;
    color: #1261FB;
}
.tamra-footer .direction.on.clickable {
    cursor: pointer;
}
.tamra-footer .direction.blue.on {
    background: #EDF2FF;
    color: #1261FB;
}
.tamra-footer .direction.green.on {
    background: #E5FAF4;
    color: #04A7A7;
}
/* .tamra-footer .direction.green.on > img {
    color: #04A7A7;
} */
.tamra-footer > button {
    font-size: 14px;
    padding: 1em 2.5rem;
    background: #111111;
    border-radius: 8px;

    font-weight: 700;
    font-size: 14px;
    line-height: 135.99%;
}
.tamra-footer > button:hover {
    background: #111111;
    /* color: #212529; */
}
.tamra-footer > button:active {
    background: #111111 !important;
}
.tamra-footer > button:focus {
    background: #111111;
}
.tamra-footer > div.button-line {
    position: relative;
    width: 100%;
}
.tamra-footer > div.button-line > div {
    position: absolute;
    left: 0;
    bottom: 0;

    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;

    font-size: 16px;
    font-weight: 400;
    color: #707991;
}
.tamra-footer > div.button-line > div > b {
    font-size: 1.5em;
    font-weight: 700;
    color: black;
}
.tamra-footer > div.button-line > button {    
    font-size: 14px;
    padding: 1em 2.5rem;
    background: #111111;
    border-radius: 8px;

    float: right;

    font-weight: 700;
    font-size: 14px;
    line-height: 135.99%;
}
.tamra-footer > div.button-line > button:hover {
    background: #111111;
    /* color: #212529; */
}
.tamra-footer > div.button-line > button:active {
    background: #111111 !important;
}
.tamra-footer > div.button-line > button:focus {
    background: #111111;
}
.tamra-go-back-icon {
    cursor: pointer;
}

.tamra-modal {
    padding-inline: 28px;
}

.font-SUIT {
    font-family: SUIT Variable;
    font-weight: 400;
}
.font-SUIT * {
    font-family: SUIT Variable !important;
    font-weight: 400;
}
.font-weight-100 {
    font-weight: 100;
}
.font-weight-200 {
    font-weight: 200;
}
.font-weight-300 {
    font-weight: 300;
}
.font-weight-400 {
    font-weight: 400;
}
.font-weight-500 {
    font-weight: 500;
}
.font-weight-600 {
    font-weight: 600;
}
.font-weight-700 {
    font-weight: 700;
}
.font-weight-800 {
    font-weight: 800;
}
.font-weight-900 {
    font-weight: 900;
}
.tamra-shuttle-date {
    position: absolute; 
    width: 100%;
    margin-top: calc(100vh - 300px);
    padding-bottom: 80px;
    background: #FFF;
    z-index: 10;
}
.tamra-shuttle-date.relative {
    position: relative;
    margin-top: 0;
}
.tamra-shuttle-date .line-divider {
    margin: 0;
    height: 8px;
}
.tamra-shuttle-date-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 24px 28px;
    gap: 16px;
    
}
.tamra-shuttle-date-text div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 100%;
}
.tamra-shuttle-date-text div div:first-child{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.03em;
    color: #323B54;
    width: 100%;
}
.tamra-shuttle-date-text div div:last-child{
    padding: 0px 24px;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    opacity: 0.5;
    width: 100%;
}
.tamra-shuttle-date button.selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 5.61905px;
    gap: 5.62px;
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    opacity: 1;
    background: #111111;
    font-weight: 700;
    line-height: 135.99%;
    border: none;
    border-radius: 0px;
}
.tamra-shuttle-date button.selector:disabled {
    background: #CBD2DD;
}
.tamra-shuttle-date-selector {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 24px 28px;
    gap: 16px;

    height: 106px;

    background: #FFFFFF;
    overflow: overlay;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;  /* mozilla */
}
.tamra-shuttle-date-selector::-webkit-scrollbar {
    height: 8px;
    opacity: 0.2;
}
.tamra-shuttle-date-selector::-webkit-scrollbar-thumb {
    background-color: rgba(150, 150, 150, 0.5);
    border-radius: 4px;
    opacity: 0.5;
}
.tamra-shuttle-date-selector :first-child {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
}
.tamra-shuttle-date-selector :first-child * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11.8px 20.0px;
    text-align: center;

    width: 56px;
    height: 58px;
    
    /* grey/100 */
    
    border-radius: 4px;
    overflow: hidden;
}
.tamra-shuttle-date-selector :first-child div.month {
    padding: 1rem;
    font-weight: 600;
    font-size: 16px;
    color: #707991;
}
.tamra-shuttle-date-selector :first-child button.date {
    background: #FFFFFF;
    border: 1px solid #CBD2DD;
    color: #464F69;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    cursor: pointer;
}
.tamra-shuttle-date-selector :first-child button.date.selected {
    background: #2A2A2A;
    border: 1px solid #111111;
    color: #FFFFFF;
}
.tamra-shuttle-date-selector :first-child button.date:disabled {
    border: 1px solid #E8E8EF;
    background: #EFF0F5;
    color: #B2BBCA;
}
.tamra-shuttle-date-selector :first-child button.date :first-child {
    font-weight: 700;
    padding: 0;
}
.tamra-shuttle-date-selector :first-child button.date :nth-child(2) {
    font-weight: 500;
    font-size: 10px;
    padding: 0;
    line-height: 140%;
}
.tamra-shuttle-time-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px;
    gap: 6px;
    background: #FFFFFF;
    width: 100%;
}
.tamra-shuttle-time-selector.invisible {
    visibility: hidden;
    padding: 0;
    margin: 0;
}
.tamra-shuttle-time-selector .time-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
}
.tamra-shuttle-time-selector .time-box button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11.8213px 20.0053px;

    width: 75px;

    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.02em;

    background: #FFFFFF;
    border: 1px solid #CBD2DD;
    border-radius: 4px;
    color: #464F69;
}
.tamra-shuttle-time-selector .time-box button.selected {
    border: 1px solid #111111;
    background: #2A2A2A;
    color: #FFFFFF;
    border: 1px solid #111111;
}
.tamra-shuttle-time-selector .time-box button:disabled {
    background: #EFF0F5;
    border: 1px solid #E8E8EF;
    color: #B2BBCA;
}


.tamra-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0 0;
    gap: 32px;
    width: 100%;
}
.tamra-form:has(button.tamra-confirm-button) {
    padding-bottom: calc(80px - 3rem);
}
.tamra-form .title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 150%;
    
    letter-spacing: -0.03em;
    
    color: #323B54;
}
.tamra-form .select-passenger-num {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
}
.tamra-form > .select-passenger-num > div.enable-children {
    width: 100%;
}
.tamra-form > .select-passenger-num div.select-num {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 100%;
    
}
.tamra-form > .select-passenger-num div.select-num button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 35px;
    gap: 4px;
    background: #EFF0F5;
    border: none;
    color: #464F69;
    font-weight: 700;

    flex: none;
    order: 0;
    flex-grow: 1;
}
.tamra-form > .select-passenger-num div.select-num button.selected {
    background: #2A2A2A;
    color: #FFFFFF;
}
button.tamra-confirm-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 5.61905px;
    gap: 5.62px;
    
    /* position: absolute; */
    position: fixed;
    width: 100%;
    height: 80px;
    left: 0px;
    bottom: 0px;
    
    background: linear-gradient(270deg, #15CDC2 0%, #3572F9 31.18%, #003C97 93.7%);

    font-size: 16px;
    font-weight: 700;
    line-height: 135.99%;
    color: #FFFFFF;
    border: none;
    border-radius: 0;
}
button.tamra-confirm-button:disabled {
    background: #CBD2DD;
}
.tamra-form .agreement-box {
    position: relative;
    height: fit-content;
    min-height: 300px;
    width: calc(100% + 2 * 28px);
    padding: 28px;
    left: -28px;

    background: #F7F8FA;
}
.tamra-form .agreement {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 12px;
}
.tamra-form .agreement .agree-info-content {
    padding-left: 0.5em;
}
.tamra-form.agreement b {
    font-weight: 700;
}
.tamra-form .agreement .link {
    font-size: 12px;
    line-height: 175%;
    /* identical to box height, or 21px */
    width: fit-content;
    float: right;
    
    text-align: right;
    letter-spacing: -0.02em;
    
    color: #3569FE;
    cursor: pointer;
}
.tamra-form .agreement .link:hover {
    text-decoration-line: underline;
}
.tamra-modal * {
    font-family: SUIT Variable !important;
    font-weight: 400;
}
.tamra-modal > .modal {
    padding-inline: 1.75rem;
}
.tamra-modal .modal-container {
    border-radius: 12px;
    padding: 20px 16px 16px;
}
.tamra-modal .modal-container > .modal-header {
    padding: 0 12px 16px;
}
.tamra-modal .modal-container > .modal-header * {
    font-weight: 600;
    font-size: 18px;
}
.tamra-modal .modal-container > .modal-footer {
    border: none;
    padding: 0px;
    gap: 11px;
    margin-top: 32px;
}
.tamra-modal .modal-container > .modal-footer > button {
    font-weight: 700;
    font-size: 14px;
    border-radius: 0.5rem;
    height: 48px;
    border: none;
}
.tamra-modal .modal-container > .modal-footer > button:first-child {
    background: #CBD2DD;
    color: #707991;
    width: 4rem;
}
.tamra-modal .modal-container > .modal-footer > button:last-child {
    background: #111111;
    flex-grow: 1;
}

.tamra-modal .modal-dialog.register {
    max-width: 20rem;
}
.tamra-modal .modal-container.register {
    padding-top: 36px;
}
.tamra-modal .modal-container.register .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 7px;
    align-self: stretch;

    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.03em;
    width: 100%;
}
.tamra-modal .modal-container.register .modal-body > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 36px;
    width: 100%;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
}
.tamra-modal .modal-container.register .modal-body > div .text {
    width: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
}
.tamra-modal .modal-container.register .modal-body > div .text > p {
    color: #FF4040;
    display: inline;
    margin: 0;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
}
.tamra-modal .modal-container.register .modal-body > div .link {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #2E2E52;
    opacity: 0.6;

    cursor: pointer;
}
.tamra-modal .modal-container.register .modal-body > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    
    width: inherit;
    background: #111111;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 135.99%;
    letter-spacing: -0.02em;
}

.tamra-phone-input-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
}
.tamra-phone-input-box > :first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    
    flex-grow: 1;
    width: 100%;
}
.tamra-phone-input-box form {
    width: 100%;
    
}
.tamra-phone-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    width: 100%;
    margin: 0px;
}
.tamra-phone-input .feedback {
    display: block;
    margin: 0;
    font-weight: 500;
    font-size: 12px;
}
.tamra-phone-input .feedback.valid {
    color: #3569FE;
}
.tamra-phone-input .feedback.invalid {
    color: #FF4040;
}
.tamra-phone-input .input-box {
    display: inherit;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 100%;
}
.tamra-phone-input .input-box input {
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
.tamra-phone-input .input-box.shadow {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    box-shadow: none !important;
}
.tamra-phone-input input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 16px;
    gap: 4px;

    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;

    letter-spacing: -0.02em;
    color: #111111 !important;
    
    width: 207px;
    height: 48px;
    
    /* grey/100 */
    
    background: #FFFFFF;
    /* navy/600 */
    
    border: 1px solid #262F4A;
    border-radius: 8px;
    
    flex-grow: 1;
    background-image: none !important;
}
.tamra-phone-input input:read-only {
    background: #EFF0F5;
    border: 1px solid #C9CAD3;
    color: #9697A3 !important;
}
.tamra-phone-input.empty{
    color: #9697A3;
}
.tamra-phone-input.empty .input-box {
    filter: none;
}
.tamra-phone-input.empty input {
    border: 1px solid #AEAFBA;
}
.tamra-phone-input button:disabled {
    background: #AEAFBA;
}
.tamra-phone-input button.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 4px; 
    font-weight: 700;
    font-size: 14px;
    line-height: 135.99%;
    width: 100px;
    height: 3rem;
    border: none;
    background: #2A2A2A;
    border-radius: 8px;
    
}
.tamra-phone-input button.btn {
    background: #2A2A2A;
}
.tamra-phone-input button:active.btn:not(.disabled):not(:disabled) {
    background: #2A2A2A !important;
    border: none;
}
.tamra-phone-input.empty button:disabled {
    background: #EFF0F5;
    color: inherit;
}

.tamra-index {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 14px;
    height: 1.8em;
    gap: 0.8em;
    width: fit-content;
}
.tamra-index > div {
    position: relative;
    border-radius: 50%;

    height: inherit;
    background: #CBD2DD;
    color: #FFFFFF;
    text-align: center;
    flex-shrink: 1;
}
.tamra-index > div.selected {
    background: #3569FE;
}
.tamra-index > div > span {
    position: absolute;
    display: table-cell;
    font-weight: 800;
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% - 0.01em), calc(-50% - 0.05em));
    vertical-align: top;
}
.tamra-index > div > div:last-child {
    display: none;
}
.tamra-index > div:not(:last-child) > div {
    display: block;
    position: absolute;
    right: -0.8em;
    top: 50%;
    transform: translate(0, -50%);

    width: 1em;
    height:0.15em;
    background: #CBD2DD;
    z-index: -1;
}

.tamra-register {
    padding: 4.5rem 28px 100px;
    position: relative;
    min-height: 100%;
}
.tamra-register > div.wrapper {
    width: 100%;
    margin: 24px 0 0;
}
.tamra-register > div.wrapper .title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 4rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
}
.tamra-register > div.wrapper .title-wrapper {
    margin-bottom: 4rem;
}
.tamra-register > div.wrapper .title-wrapper > .title {
    margin-bottom: 0;
}
.tamra-register > div.wrapper .title small {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.03em;
    color: #707991;
}
.tamra-register > div.wrapper .sub-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
    /* or 20px */
    
    letter-spacing: -0.02em;
    
    /* navy/200 */
    
    color: #98A0B3;
}
.tamra-register > div.wrapper > .agreement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    
}
.tamra-register > div.wrapper > .agreement > :nth-child(n){
    width: 100%;
}
.tamra-register > div.wrapper > .agreement b {
    font-weight: 700;
}
.tamra-register > div.wrapper > .agreement .agree-info-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.tamra-register > div.wrapper > .agreement .agree-info-content {
    padding-left: 0.5em;
}
.tamra-register > div.wrapper > .agreement button {
    width: fit-content;
    float: right;
    margin: 0;
}
.tamra-register > div.wrapper > .custom-checkbox-shape {
    margin: 6rem 0 2rem;
}
.tamra-register > div.wrapper > .personal-info-box {
    display: flex;
    flex-direction: column;
    gap: 42px;
    padding-bottom: 100px;
}
.tamra-register > div.wrapper > .survey-box {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding-bottom: 128px;
}
.tamra-register > div.wrapper > .survey-box .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}
.tamra-register > div.wrapper > .survey-box .form-group > label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    
    color: #323B54;
}
.tamra-register > div.wrapper > .survey-box .form-group > .custom-radio-shape {
    padding: 0px 20px;
}
.tamra-register > div.wrapper > .survey-box .form-group > div.button-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 100%;
}
.tamra-register > div.wrapper > .survey-box .form-group > div.button-group button {
    font-weight: 700;
    font-size: 14px;
    padding: 14px 35px;

    color: #464F69;

    background: #EFF0F5;
    border: none;
    border-radius: 8px;

    flex-grow: 1;
}
.tamra-register > div.wrapper > .survey-box .form-group > div.button-group button.active {
    background: #1261FB;
    color: #EFF0F5;
}
.tamra-register > div.wrapper > .survey-box .form-group > .collapse {
    width: 100%;
}
.tamra-register > div.wrapper > .survey-box .form-group > .collapsing {
    width: 100%;
}
.tamra-register > .skip-button {
    position: fixed;
    bottom: 116px;
    left: 50%;
    transform: translate(-50%, 0);

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    
    background: rgba(24, 24, 24, 0.8);
    border-radius: 19px;

    font-weight: 600;
    font-size: 14px;
    line-height: 135.99%;
    color: #FFFFFF;

    cursor: pointer;
}
.tamra-register-modal {   
    max-width: 350px;
    margin: auto;
}
.tamra-register-modal .modal-content {
    border-radius: 12px;
    border: none;
}
.tamra-register-modal .modal-content > .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 16px 16px;
    gap: 32px;
}
.tamra-register-modal .modal-content > .modal-body > div {
    font-weight: 600;
    font-size: 18px;
    line-height: 135.99%;
    /* or 24px */
    
    text-align: center;
    letter-spacing: -0.02em;
    color: #202A43;
}
.tamra-register-modal .modal-content > .modal-body > img {
    width: 48px;
    height: 48px,
}
.tamra-register-modal .modal-content > .modal-body > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;

    font-weight: 700;
    font-size: 14px;
    /* Black/300 */
    
    background: #111111;
    border-radius: 8px;
}

.tamra-bottom-box {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #FFF;
    z-index: 10;
}
.tamra-bottom-box.down-scroll {
    margin-top: calc(100vh - 300px);
    bottom: auto;
}
.tamra-bottom-box.relative {
    position: relative;
}
.tamra-bottom-box.has-button {
    padding-bottom: 100px;
}
.tamra-bottom-box > .reservation-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 28px;
    gap: 16px;


    color: #202A43;
}
.tamra-bottom-box > .reservation-title > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 13px;

    font-weight: 600;
    font-size: 18px;
    line-height: 135.99%;
    letter-spacing: -0.02em;
}
.tamra-bottom-box > .line-divider {
    margin: 0;
}
.tamra-bottom-box > .info-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 24px 28px;
    gap: 32px;
}
.tamra-bottom-box > .info-box > .eta-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    padding: 0px;
    gap: 6px;
}
.tamra-bottom-box > .info-box > .eta-box :first-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 135.99%;
    color: #707991;
}
.tamra-bottom-box > .info-box > .eta-box :last-child {
    font-weight: 700;
    font-size: 32px;
    line-height: 135.99%;
    
    letter-spacing: -0.03em;
    color: #202A43;
}
.tamra-bottom-box > button {
    width: 100%;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;

    background: #111111;

    border-radius: 0;
}
.tamra-bottom-box > button:hover {
    background: #111111;
}
.tamra-bottom-box > button:focus {
    background: #111111 !important;
}
.tamra-bottom-box > button:active {
    background: #111111 !important;
}
.tamra-bottom-box:not(.down-scroll, .has-button) > button {
    height: 80px;
    bottom: 0;
}


.tamra-information-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
    width: 100%;
}
.tamra-information-box.modal-body {
    padding: 16px 12px 0px;
}

.tamra-information-box > :nth-child(n) {
    display: inherit;
    flex-direction: row;
    align-items: inherit;
    padding: 0;
    gap: inherit;

    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: -0.02em;
}.tamra-information-box > :nth-child(n) > * {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: -0.02em;
}
.tamra-information-box > :nth-child(n) > :first-child {
    color: #98A0B3;
    flex-shrink: 0;
}
.tamra-information-box > :nth-child(n) > :last-child {
    color: #202A43;
}
.tamra-information-box > .list > :last-child {
    display: inherit;
    flex-direction: column;
    align-items: inherit;
    padding: 0;
    gap: 6px;

    color: #1261FB;
}

.tamra-cancel-modal {
    max-width: 350px;
    margin: auto;
}
.tamra-cancel-box {
    border-radius: 12px;
}
.tamra-cancel-box > .modal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 36px 16px 16px;
    gap: 32px;
}

.tamra-cancel-box > .modal-body > .main-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    gap: 8px;
    width: 100%;
}
.tamra-cancel-box > .modal-body > .main-box :first-child {
    font-weight: 600;
    font-size: 18px;
    line-height: 135.99%;

    letter-spacing: -0.02em;
}
.tamra-cancel-box > .modal-body > .main-box :last-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;

    color: #707991;
}
.tamra-cancel-box > .modal-body > .button-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    gap: 11px;

    width: 100%;
}
.tamra-cancel-box > .modal-body > .button-group button {
    padding: 16px;
    flex-grow: 1;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
}
.tamra-cancel-box > .modal-body > .button-group button:first-child{
    max-width: 4rem;
    background: #CBD2DD;
    color: #707991;
    border: none;
}
.tamra-cancel-box > .modal-body > .button-group button:last-child{
    background: #EE3858;
    color: #FFFFFF;
    border: none;
}

.tamra-footer.jungmun > .icon-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 100%;
}
.tamra-footer.jungmun > .icon-box > button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 24px;
    gap: 7px;
    
    background: #FFFFFF;
    /* grey/400 */
    
    border: 1px solid #E8E8EF;
    box-shadow: 0px 4px 15px rgba(0, 70, 174, 0.06);
    border-radius: 8px;
    flex-grow: 1;
}
.tamra-footer.jungmun > .icon-box > button > div {
    color: #464F69;
    font-weight: 500;
    font-size: 14px;
}
.tamra-footer.jungmun > .icon-box > button > img {
    width: 75px;
    height: 75px;
}

.tamra-jungmun-topbar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 20px;
    gap: 4px;
    
    position: absolute;
    top: 0;
    width: 100%;

    font-weight: 500;
    
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
}
.tamra-jungmun-topbar > span {
    margin-top: 2em;
}
.tamra-jungmun-topbar > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 14px;
}
.tamra-jungmun-topbar > div > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.02em;
    
    color: #373737;
}
.tamra-jungmun-topbar > div > div > span {    
    opacity: 0.5;
}
.tamra-jungmun-topbar .available-radius-icon {
    box-sizing: border-box;
    
    width: 14px;
    height: 14px;
    
    border: 1px dashed #000000;
    border-radius: 50%;
}
.tamra-jungmun-topbar .path-icon {
    width: 14px;
    height: 4px;
    
    background: rgba(4, 167, 167, 0.8);
    opacity: 1;
    /* border: 3px solid #FFFFFF; */
}

.tamra-toast-container {
    position: absolute;
    bottom: 35%;
    left:50%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 100;
    pointer-events: none;
    transform: translate(-50%, 0);
}
.tamra-toast {
    /* position: absolute;
    bottom: 35%;
    left:50%; */
    width: fit-content;

    display: flex;
    flex-direction: row;
    flex-basis: auto;
    align-items: flex-start;
    padding: 10px 22px;
    gap: 4px;

    background: rgba(24, 24, 24, 0.8);
    border-radius: 19px;

    font-weight: 600;
    font-size: 14px;
    line-height: 135.99%;
    
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;

    z-index: 100;
    pointer-events: none;
}
.tamra-toast.show{
    white-space: nowrap;
}

.tamra-complete-page {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, #D6DBE7 100%);
}
.tamra-complete-page > div.title {
    position: absolute;
    width: fit-content;
    left: 10%;
    top: min(13%, 111px);
    
    font-family: 'SUIT';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 135.99%;
    /* or 33px */
    
    letter-spacing: -0.02em;
    
    /* navy/700 */
    
    color: #202A43;
}
.tamra-complete-page > div.image-box {
    position: absolute;
    width: 100%;
    bottom: min(180px, calc(100px + 2vh));
    overflow: hidden;
    pointer-events: none;
}
.tamra-complete-page > div.image-box > img {
    position: relative;
    width: 70%;
    margin-left: 25px;
    margin-top: 35px;
    z-index: 100;
    pointer-events: none;
}
.tamra-complete-page > div.image-box > .bars {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    top: 0;
}
.tamra-complete-page > div.image-box > .bars > .bar {
    /* position: absolute; */
    width: 100%;
    height: 11px;
    left: 0%;
    top: 0%;
    
    /* green / 050 */
    
    border-radius: 17px;
    background: #1261FB;
    z-index: 10;
}
.tamra-complete-page.black > div.image-box > .bars > .bar {
    background: #2A2A2A;
}
.tamra-complete-page > div.image-box > .bars > .bar.bright {
    background: #22CEAF;
}
.tamra-complete-page.black > div.image-box > .bars > .bar.bright {
    background: #2A2A2A;
    opacity: 0.3;
}
.tamra-complete-page > div.image-box > .bars > :nth-child(1) {
    margin-left: 66%;
}
.tamra-complete-page > div.image-box > .bars > :nth-child(2) {
    margin-left: 46%;
}
.tamra-complete-page > div.image-box > .bars > :nth-child(3) {
    margin-left: 50%;
}
.tamra-complete-page > div.image-box > .bars > :nth-child(4) {
    margin-left: 50%;
}

.tamra-complete-page > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 5.61905px;
    gap: 5.62px;
    
    position: absolute;
    width: 100%;
    height: 80px;
    left: 0px;
    bottom: 0px;
    border: none;
    border-radius: 0%;
    background: linear-gradient(270deg, #15CDC2 0%, #3572F9 31.18%, #003C97 93.7%);

    font-weight: 700;
    font-size: 16px;
    line-height: 135.99%;
    
    letter-spacing: -0.02em;
}
.tamra-complete-page.black > button {
    background: #111111;
}

.modal.footer-modal > .modal-dialog {
    margin: 0;
    height: 100%;
    max-width: 100%;
    align-items: flex-end;
    justify-content: center;
}
.tamra-footer-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 28px;
    gap: 32px;

    width: 100%;

    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 12px 12px 0px 0px;
}

.tamra-footer-modal > div.text {
    text-align: center;
}
.tamra-footer-modal > div.text > span {
    font-weight: 600;
    font-size: 18px;
    line-height: 135.99%;    
    letter-spacing: -0.02em;
}
.tamra-footer-modal > div.text > span:first-child {
    color: #707991;
}
.tamra-footer-modal > div.text > span > b {
    color: #0C9988;
    font-weight: inherit;
}
.tamra-footer-modal > div.button-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 19px;
}
.tamra-footer-modal > div.button-group > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 4px;
    
    border-radius: 8px;
    border: none;

    font-weight: 700;
    font-size: 14px;
    line-height: 135.99%;
    
    text-align: center;
    letter-spacing: -0.02em;
}
.tamra-footer-modal > div.button-group > button.cancel {
    background: #CBD2DD;
    color: #707991;
}
.tamra-footer-modal > div.button-group > button.select {
    width: 150px;
    background: #0C9988;
    color: #FFFFFF;
}
.tamra-admin-reservation tr.served:nth-of-type(n) {
    background-color: rgba(162, 255, 167, 0.8);
}
.tamra-admin-reservation :is(tr.impending:nth-of-type(n),tr.onehour:nth-of-type(n)) {
    background-color: rgba(255, 213, 0, 0.957);
}
.tamra-admin-reservation tr.expired:nth-of-type(n) {
    background-color: rgb(217, 52, 7);
}
.tamra-admin-reservation tr.cancel:nth-of-type(n) {
    background-color: rgb(34, 0, 255);
}
.tamra-admin-reservation tr.cancel:nth-of-type(n) > * {
    color: #fff;
}


.dark-mode .tamra-admin-reservation tr.served:nth-of-type(n) {
    background-color: rgb(162, 255, 167);
}
.dark-mode .tamra-admin-reservation tr.served:nth-of-type(n) > * {
    color: #1f1f1f;
}
.dark-mode .tamra-admin-reservation :is(tr.impending:nth-of-type(n),tr.onehour:nth-of-type(n)) {
    background-color: rgba(255, 221, 52, 0.981);
}
.dark-mode .tamra-admin-reservation :is(tr.impending:nth-of-type(n),tr.onehour:nth-of-type(n)) > * {
    color: #1f1f1f;
}
.dark-mode .tamra-admin-reservation tr.expired:nth-of-type(n) {
    background-color: rgb(181, 4, 4);
}
.dark-mode .tamra-admin-reservation tr.cancel:nth-of-type(n) {
    background-color: rgba(28, 0, 208, 0.533);
}


.tamra-kakao-map-wrapper {
    position: relative;
    min-height: 100%;
}
.tamra-kakao-map-wrapper.flex {
    display: flex;
    flex-direction: column;
}
.tamra-kakao-map {
    width: 100%;
    height: 75vh;
    position: fixed;
    top: 0;
}
.tamra-kakao-map-wrapper.flex > .tamra-kakao-map {
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50vh;
    height: auto;
}
.tamra-agreement-modal > .modal-dialog {
    max-width: 100%;
    width : 768px;
    margin-top: 6rem;
    margin-inline: auto;
}
.tamra-agreement-modal .tamra-agreement-modal-content {
    padding-inline: 28px;
}
.tamra-agreement-modal .tamra-agreement-modal-content > div.modal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 28px 0px 0px;
    gap: 40px;
}
.tamra-agreement-modal-content h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;

    letter-spacing: -0.02em;

    color: #1261FB;
}
.tamra-agreement-modal-content p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px 10px;
    gap: 40px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: -0.02em;
    
    color: #707991;
}
.tamra-agreement-modal-content > div.modal-body > .data .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}
.tamra-agreement-modal-content ol > li {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;    
    letter-spacing: -0.02em;
    margin: 15px 0 10px;
    
    color: #323B54;
}
.tamra-agreement-modal-content ul > li {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 0 10px;
    letter-spacing: -0.02em;    
    color: #707991;    
}
.tamra-agreement-modal .tamra-agreement-modal-content > div.modal-footer {
    display: flex;
    justify-content: center;
    margin: 1rem;
}
.tamra-agreement-modal .tamra-agreement-modal-content > div.modal-footer > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 4px;
    
    width: 150px;
    height: 48px;

    background: #111111;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}
.no-zoom {
    touch-action: manipulation
}