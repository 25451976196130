.main {
  position: relative;
  width: auto;
  max-width: 100%;
  height: auto;
  min-height: 100%;
}
.main.flex{
  display: flex;
  flex-flow: column;
}
.Seogwipo-Main-top-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  /* max-height: 276px; */
  overflow: hidden;
}
  
.Seogwipo-Main-box{
  position: relative;
  padding: 8.2vh 0 1.5em 2rem;
}
.Seogwipo-Main-box div {
  position:"relative";
  width: auto;
  height: auto;
}
.Seogwipo-Main-box div.Seogwipo-logo-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
img.JDC-Logo {
  height: 5.2vh;
  min-height: 44px;
  transform: translateY(-10%);
}
#Logo-Cross{
  height: 1.3vh;
  min-height: 10px;
  margin-top: 1vh;
}
.Seogwipo-Main-box span{
  position: relative;
}
.Seogwipo-Main-box .service-introduction{
  width: fit-content;
  height: auto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.7px;
  margin: 1.2em 0 0 0;
  color: #5a5a5a;
  cursor: pointer;
  display: block;
}
.Seogwipo-Main-box .service-introduction div{
}

.Seogwipo-Main-box .service-introduction .arrow-forward{
  height: 12px;
  margin: 0 0 2px 4px;
}

.Seogwipo-Main-round-box {
  position:relative;
  flex: 1;
  overflow: hidden;
  border-radius: 3vw 3vw 0 0;
  width: 100vw;
  height: auto;
  font-size: 7vh;
  margin: 0 0 0;
  padding: 1.5rem 0 2rem;
  background-color: #fff;
  align-items: center;
}

.Seogwipo-Main-service {  
  position: relative;
  display: flex;
  width: 90vw;
  height: max(118px, 25%);
  font-size: 25%;
  margin: 0 5vw 5vh 5vw;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1.5px #36f;
  background-color: #fff;
  padding: 1.5rem 1.5rem;
  border-radius: 1rem;
  cursor: pointer;
  align-items: center;
}
.Seogwipo-Main-service:hover {  
  background-image: linear-gradient(to right, #36f 0%, #5c41f5 100%);
  color: #fff;
}

.Seogwipo-Main-service .text{  
  width: 70%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  /* margin: 9px 141px 8px 0; */
  font-size: min(max(22px, 1.5em), 5vw);
  font-weight: 500;
  line-height: 1.64;
  letter-spacing: -0.44px;
  text-align: left;
  color: #386af6;
}
.Seogwipo-Main-service:hover .text{  
  color: #f8f8f8;
}
.Seogwipo-Main-service img{  
  position: absolute;
  right: 7%;
  min-height: 80px;
  max-height: 18vw;
  height: 60%;
}

.Seogwipo-Main-round-box div.register-box-wrapper {
  text-align: center;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Seogwipo-Main-round-box div.register-box-wrapper div.register-info {
  font-size: 13.5px;
  letter-spacing: normal;
}
.Seogwipo-Main-round-box div.register-box-wrapper div.register-box {
  border: solid 1.5px #36f;
  border-radius: 1.2em;
  background-image: linear-gradient(to right, #36f 2%, #5c41f5);
  margin: 1em 0 30px 0;  
  padding: 5px 18px 5px 25px;
  color: #fff;
  font-weight: 500;
  letter-spacing: normal;
  cursor: pointer;
}
.Seogwipo-Main-round-box div.register-box-wrapper div.register-box:hover {
  background-color: #fff;
  background-image: none;
  color: #36f;
}
.Seogwipo-Main-round-box div.register-box-wrapper div.register-box .arrow-forward.register-icon{
  margin-bottom: 0.25em;
  margin-left: 5px;
}